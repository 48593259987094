import logo from "./assets/img/89666.png";

import "./App.css";

function App() {
  return (
    <div className="App bg-[url('./assets/img/bg.jpg')] bg-no-repeat bg-cover min-h-[100vh] flex justify-center items-center relative bg-center">
      <div className="px-5 py-10">
        <div className="max-w-5xl mx-auto">
          <img className="max-w-xs mx-auto" src={logo} alt="" />
          <div className="py-5 text-center hokkaido lg:py-10">
            <h1 className="font-bold text-4xl">
              Hokkaido inu ほっかいどうけん
            </h1>
            <p className="mt-3 text-xl w-full">
              Official CA:{" "}
              <span className="font-bold">
                0xd73e9a5a2203bCA0e7a6b600Bb73đ6c72d1C6E2
              </span>
            </p>
            <p className="text-xl">Supply: 100,000,000,000</p>
            <p className="text-xl">Tax 0/0</p>
          </div>
          <ul className="pt-5 flex justify-center gap-4 md:gap-8">
            <li>
              <a className="bg-black py-3 px-5 rounded-xl text-white transition-all hover:bg-white hover:text-black">
                Contract
              </a>
            </li>
            <li>
              <a
                href="https://www.dextools.io/app/en/ether/pair-explorer/0xf0510f7f8daf49ba75e773e9ae8b67a81c6665b8"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black py-3 px-5 rounded-xl text-white transition-all hover:bg-white hover:text-black"
              >
                Chart
              </a>
            </li>
          </ul>
        </div>
        <div className="hidden mx-auto sm:block md:w-[700px] lg:w-[1024px]">
          <iframe
            className="py-10 chart mx-auto"
            id="dextools-widget"
            title="DEXTools Trading Chart"
            width="90%"
            height="600"
            src="https://www.dextools.io/widgets/en/ether/pe-light/0xf0510f7f8daf49ba75e773e9ae8b67a81c6665b8?theme=light&chartType=1&chartResolution=30&drawingToolbars=false"
          ></iframe>
        </div>
      </div>
      <ul className="flex gap-4 md:gap-5 absolute left-12 bottom-8 font-medium text-sm">
        <li>
          <a
            href="https://twitter.com/officehokk"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-black py-2 px-3 rounded-xl text-white transition-all hover:bg-white hover:text-black"
          >
            Twitter
          </a>
        </li>
        <li>
          <a
            href="https://t.me/officehokk"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-black py-2 px-3 rounded-xl text-white transition-all hover:bg-white hover:text-black"
          >
            Telegram
          </a>
        </li>
      </ul>
    </div>
  );
}

export default App;
